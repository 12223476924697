import React from 'react';
import {
    SafeAreaView,
    View,
    Image,
    Text,
    Linking,
} from 'react-native';
import { 
    Provider 
} from 'react-native-paper';
import {
    DrawerContentScrollView,
    DrawerItemList,
} from '@react-navigation/drawer';
import styles from '../styles';
import UserDrop from './UserDrop';

const CustomSidebarMenu = (props) => {
    const BASE_PATH =
        'https://raw.githubusercontent.com/AboutReact/sampleresource/master/';

    return (
    <Provider>
        <SafeAreaView style={{flex: 1}} >
            <UserDrop style={{flex: 4}} />
            <DrawerContentScrollView style={{flex: 8}} {...props} >
                <DrawerItemList {...props} />
            </DrawerContentScrollView>
            <View style= {{paddingBottom: 15, flex: .75}}>
                <Text 
                    style={styles.bottomSideBarText}
                    onPress={() => {
                    Linking.openURL('https://integrityhomeexteriors.com');
                }}>
                        IntegrityHomeExteriors.com
                    <Image
                        style={{width: 40, height: 40, marginLeft: 5}}
                        source={{uri: 'https://d2q79iu7y748jz.cloudfront.net/s/_squarelogo/389444d4726ffbe7a90fb54b4b781718'}}
                    /> 
                </Text>
            </View>
        </SafeAreaView>
    </Provider>
    );
};

export default CustomSidebarMenu;
