import 'react-native-gesture-handler';
import React, { useState,useEffect } from "react";
import {
  Text,
  View,
  Button,
  ActivityIndicator,
  TextInput,
  Linking
} from "react-native";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import { KeycloakProvider, useKeycloak } from "expo-keycloak-auth";
import AppConfig from "./app.json";
import ScreenStack from './components/ScreenStack';
import styles from './styles';

export default function App() {
  const keycloakConfiguration = {
    clientId: "wms",
    realm: "Integrity_Home_Exteriors", // your realm name
    url: "https://keycloak.integrityprodserver.com", // This is usually a url ending with /auth
    scheme: AppConfig.expo.scheme,
    secret: "9tJlvVpaz7dVbLdK8RsDpK4WkxAuLOJg",
    refreshTimeBuffer : 59
  };

  return (
    <KeycloakProvider {...keycloakConfiguration}>
        <Auth />
    </KeycloakProvider>
  );
}

export const Auth = () => {
  const {
    ready, // If the discovery is already fetched and ready to prompt authentication flow
    login, // The login function - opens the browser
    isLoggedIn, // Helper boolean to use e.g. in your components down the tree
    token, // Access token, if available
    logout, // The logout function - Logs the user out
  } = useKeycloak();

  global.token = { token }['token'];
  const urlParams = new URLSearchParams(window.location.search);
  if(urlParams.get('auth')){
      token = urlParams.get('auth');
      isLoggedIn = true;
      global.token = token;
  }

  const [user, setUser] = useState(undefined);
  const [urlBase, setUrlBase] = useState('https://wrapperapi.integrityprodserver.com/api/');

  const getUserInfo = async () => {
    if (token)  {
        try {
            const response = await fetch("https://wrapperapi.integrityprodserver.com/api/users/me", { 'headers': new Headers({ Authorization: 'Bearer ' + global.token }) });
            const json = await response.json();
            if (json.email) {
            Linking.getInitialURL().then((url) => {
                if (url.toString().includes("localhost") || url.toString().includes("intserver-dev")) {
                setUrlBase('http://intserver-dev/~' + json['email'].split('@')[0] + '/wms-alpha/wms-backend/public/api/');
                }
            });
            }
            setUser(json);
        }
        catch (error) {
            console.error(error);
        }
    }
    // return getUserInfo;
  };

  useEffect(() => {
  getUserInfo();
  }, [global.token]);

  global.userInfo = user;
  global.urlBase = urlBase;
  global.token = { token }['token'];
  global.logout = logout;

  if (!ready) return <ActivityIndicator />;

  if (!isLoggedIn)
    return (
      <View style={ styles.container }>
        <Button onPress={login} title="Login" />
      </View>
    );

  if (user)
    return (
      <ScreenStack />
    );
};

