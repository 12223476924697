import "react-native-gesture-handler";

import * as React from "react";
import { View, Text } from "react-native";

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Tasks from "../pages/Tasks";
import Tickets from "../pages/Tickets";
import styles from "../styles";

// Import Custom Sidebar
import CustomSidebarMenu from "./CustomSidebarMenu";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const NavigationDrawerStructure = (props) => {
  //Structure for the navigatin Drawer
  const toggleDrawer = () => {
    //Props to open/close the drawer
    props.navigationProps.toggleDrawer();
  };
};

const linking = {
  //  prefixes: ['http:intserver-dev:19008'],
  config: {
    screens: {
      Home: "",
      Tasks: {
        path: "tasks",
        screens: {
          My_Tasks: "mytasks",
          Scheduled_Tasks: "scheduledtasks",
          Scheduled_Templates: "scheduledtemplates",
          Task_Templates: "templatelist",
          Task_Statuses: "statusesList",
          Task_Categories: "categoriesList",
        },
      },
      Tickets: {
        path: "tickets",
        screens: {
          My_Tickets: "mytickets",
          All_Tickets: "alltickets",
        },
      },
    },
  },
};

function Home() {
  return (
    <View style={styles.container}>
      <Text>Welcome {userInfo.name}</Text>
    </View>
  );
}

function ScreenStack() {
  return (
    <NavigationContainer linking={linking}>
      <Drawer.Navigator
        screenOptions={{
          headerStyle: {
            borderBottomWidth: 1,
            borderBottomColor: "#000000",
          },
          drawerType: "front",
          unmountOnBlur: false,
          freezeOnBlur: true,
        }}
        drawerContent={(props) => <CustomSidebarMenu {...props} />}
      >
        <Drawer.Screen
          name="Home"
          options={{ title: "Home" }}
          component={Home}
        />
        <Drawer.Screen
          name="Tasks"
          options={{ title: "Tasks" }}
          component={Tasks}
        />
        <Drawer.Screen
          name="Tickets"
          options={{ title: "Tickets" }}
          component={Tickets}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}

export default ScreenStack;
