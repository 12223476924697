import React from 'react';
import {
    Text,
    View,
    Image,
    Linking,
    TouchableHighlight,
} from 'react-native';
import {
    Button,
    Menu,
    Divider,
} from 'react-native-paper';
import styles from '../styles';

const UserDrop = () => {
    const [visible, setVisible] = React.useState(false);

    const openMenu = () => setVisible(true);

    const closeMenu = () => setVisible(false);

    return (
            <View style={styles.userDropdown}>
                <View style={styles.profileMargin}>
                    <TouchableHighlight onPress={() => Linking.openURL('https://cat-bounce.com/')}>
                        <Image
                            style={styles.userMenuProfileIcon}
            			    source={{uri: 'https://ui-avatars.com/api/?background=random&name=' + encodeURI(userInfo.name)}}
                        />
                    </TouchableHighlight>
                    <Text style={styles.username}>{userInfo.name}</Text>
                    <Menu
                        visible={visible}
                        onDismiss={closeMenu}
                        anchor={
                            <Button
                                onPress={openMenu}
                                style={styles.menu}>
                                    Menu
                            </Button>}
			>
                        <Menu.Item
                            title="User Profile"
                            onPress={() => Linking.openURL('https://keycloak.integrityprodserver.com/realms/Integrity_Home_Exteriors/account/#/personal-info')}
			/>
                        <Menu.Item
                            title="Update Password"
                            onPress={() => Linking.openURL('https://keycloak.integrityprodserver.com/realms/Integrity_Home_Exteriors/account/#/security/signingin')}
			/>
                        <Divider />
                        <Menu.Item
                            title="Log Out"
                            onPress={logout}
			/>
		</Menu>
                </View>
            </View>
    )
}

export default UserDrop;
