import React, { useState } from "react";
import {
  Button,
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
  Image,
  ActivityIndicator,
} from "react-native";
import styles from "../styles";
import { WebView } from "react-native-web-webview";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";

const Drawer = createDrawerNavigator();

const Tickets = ({ navigation }) => {
  const pageToken = token;
  var link = "https://tickets.integrityprodserver.com/";
  var auth = "?auth=" + pageToken;

  const Tickets = ({ navigation }) => {
    return (
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: link + "mytickets" + auth }} />
      </View>
    );
  };

  const AllTickets = ({ navigation }) => {
    return (
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: link + "alltickets" + auth }} />
      </View>
    );
  };


  if (!pageToken) return <ActivityIndicator />;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Drawer.Navigator
        screenOptions={{
          headerShown: false,
          drawerType: "permanent",
          overlayColor: 0,
          unmountOnBlur: false,
          freezeOnBlur: true,
        }}
        drawerContentOptions={{
          activeTintColor: "#e91e63",
          itemStyle: { marginVertical: 5 },
        }}
      >
        <Drawer.Screen
          name="My_Tickets"
          options={{ title: "My Tickets" }}
          component={Tickets}
        />
        <Drawer.Screen
          name="All_Tickets"
          options={{ title: "All Tickets" }}
          component={AllTickets}
        />
      </Drawer.Navigator>
    </SafeAreaView>
  );
};

export default Tickets;
