import { StyleSheet } from 'react-native';

export default StyleSheet.create({

    bottomSideBarText: {
        fontSize: 16,
        textAlign: 'center',
        color: 'grey',
        marginBottom: 5,
    },

    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },

    containerTwo: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: '#00ff00',
        padding: 20,
    },

    containerThree: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 200,
        paddingTop: 200,
        size: 'large',
    },

    profileMargin: {
        marginTop: 120,
    },

    menu: {
        fontWeight: 'bold',
        fontSize: 20,
        color: '#333',
        letterSpacing: 1,
        alignItems: 'center',
        textAlign: 'center',
    },

    username: {
        textAlign: 'center',
    },

    userDropdown: {
        width: '100%',
        height: '10%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 10,
        marginBottom: 100,
        borderColor: '#333',
        
    },

    userMenuProfileIcon: {
        width: 100,
        height: 100,
        borderRadius: 50,
        alignSelf: 'center',        
        justifyContent: 'center',
    },

});