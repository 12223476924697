import React, { useState } from "react";
import {
  Button,
  View,
  Text,
  SafeAreaView,
  StyleSheet,
  TouchableOpacity,
  TouchableHighlight,
  Image,
  ActivityIndicator,
} from "react-native";
import styles from "../styles";
import { WebView } from "react-native-web-webview";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createDrawerNavigator } from "@react-navigation/drawer";

const Drawer = createDrawerNavigator();

const Tasks = ({ navigation }) => {
  const pageToken = token;
  var link = "https://wms.integrityprodserver.com/";
  var auth = "?auth=" + pageToken;

  const Tasks = ({ navigation }) => {
    return (
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: link + "tasklist" + auth }} />
      </View>
    );
  };

  const ScheduledTasks = ({ navigation }) => {
    return (
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: link + "scheduledtasklist" + auth }} />
      </View>
    );
  };

  const ScheduledTemplates = ({ navigation }) => {
    return (
      <View style={{ flex: 1 }}>
        <WebView source={{ uri: link + "scheduledtemplatelist" + auth }} />
      </View>
    );
  };

  const TaskStatuses = ({navigation}) => {
        return(
                <View style={{flex:1}}>
                        <WebView source={{ uri: link+'statusesList'+auth }} />
                </View>
        )
  };

  const TaskTemplates = ({navigation}) => {
        return(
                <View style={{flex:1}}>
                        <WebView source={{ uri: link+'templateList'+auth }} />
                </View>
        )
  };

  const TaskCategories = ({navigation}) => {
        return(
                <View style={{flex:1}}>
                        <WebView source={{ uri: link+'categoriesList'+auth }} />
                </View>
        )
  };


  if (!pageToken) return <ActivityIndicator />;

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <Drawer.Navigator
        screenOptions={{
          headerShown: false,
          drawerType: "permanent",
          overlayColor: 0,
          unmountOnBlur: false,
          freezeOnBlur: true,
        }}
        drawerContentOptions={{
          activeTintColor: "#e91e63",
          itemStyle: { marginVertical: 5 },
        }}
      >
        <Drawer.Screen
          name="My_Tasks"
          options={{ title: "My Tasks" }}
          component={Tasks}
        />
        <Drawer.Screen
          name="Scheduled_Tasks"
          options={{ title: "Scheduled Tasks" }}
          component={ScheduledTasks}
        />
        <Drawer.Screen
          name="Task_Templates"
          options={{ title: "Task Templates" }}
          component={TaskTemplates}
        />
        <Drawer.Screen
          name="Scheduled_Templates"
          options={{ title: "Scheduled Templates" }}
          component={ScheduledTemplates}
        />
        <Drawer.Screen
          name="Task_Statuses"
          options={{title: 'Statuses'}}
          component={TaskStatuses}
        />
        <Drawer.Screen
          name="Task_Categories"
          options={{title: 'Categories'}}
          component={TaskCategories}
        />
      </Drawer.Navigator>
    </SafeAreaView>
  );
};

export default Tasks;
